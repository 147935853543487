<template>
  <div class="site-wrapper">
    <div class="a-main a-main--home bg-linear">
      <div class="container">
       <div class="col-md-12 forgot-form">
          <!-- <Error :errors="errors" /> -->
          <div class="buttonlnk-previous-container my-5">
             <router-link class="previous-link" :to="{ path: 'login' }">
                <svg
                class="back"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 240.823 240.823"
                
              >
                <path
                  d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"
                />
              </svg>
                  Back
                </router-link>
          </div>
          <div class="info">
            <p>
              Forgot your password? No problem. Just let us know your email
              address and we will email you a code that will allow you to choose
              a new one.
            </p>
          </div>

          <form method="POST" class="my-5" @submit.prevent="resetLink">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                class="form-control form-control--email"
                id="email"
                placeholder="Email"
                v-model="form.email"
                required
                  v-bind:class="[ message ? 'error-input' : '']"
              />
              <p v-if="message" class="error"> {{ message }}</p>
            </div>
            <button :disabled="loading" type="submit" class="btn btn-green">
              EMAIL PASSWORD RESET CODE
            </button>
          </form>
        </div>
  
      </div>
    </div>
  </div>
</template>

<script>
// import Error from "@/components/Error.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: { 
    // Error
   },
  data() {
    return {
      form: {
        email: "",
      },
      message: "",
      error: false,
      errors: [],
    };
  },

   computed: {
    ...mapState(["loading"]),
  },
  methods: {
    ...mapActions("user", ["performPasswordReset"]),
    ...mapActions(["setLoading"]),
    resetLink() {
      // if (this.checkForm()) {
          this.setLoading(true)
        this.performPasswordReset(this.form)
          .then((res) => {
            if (res.error) {
              this.error = true;
              // this.$toaster.error(res.message);
             this.setLoading('error')
            } else {
              this.error = false;
            this.setLoading(false)
              // this.$toaster.success(res.message);
              this.$router.push({
                 name: 'ResetPassword',
                  params: { email: this.form.email}
              });
            }
            this.setLoading(false)
            this.message = res.message;
          })
          .catch(() => {
            // this.$toaster.error(err.message);
            this.setLoading('error')
            this.setLoading(false)
          });
      // }
    },
    // checkForm() {
    //   if (this.form.email) {
    //     return true;
    //   }

    //   this.errors = [];
    //   if (!this.form.email) {
    //     this.errors.push("Email required.");
    //   }
    // },
  },
};
</script>

<style scoped>
.buttonlnk-previous-container{
  display: flex;
  justify-content: flex-end;

}
.buttonlnk-previous-container a{
  color: rgb(200, 200, 200);
  opacity: .7;
  transition: all .3s ease;

}
.buttonlnk-previous-container svg{
  fill:white; 
  width: .75rem; 
  margin-right: 0.1rem;
  position: relative;
  top: -1px;
}
.buttonlnk-previous-container a:hover{
  opacity: .5;
}
.forgot-text {
  text-align: center;
  margin-top: 60px;
  font-size: 30px;
  /* color: rgb(4 188 197); */
}
.forgot-form {
  margin-top: 110px;
}
.form-control {
  border: 2px solid #dddddd;
  height: calc(1.4em + 1.5rem + 25px);
}
.btn-green {
  width: 100%;
}
.or {
  text-align: center;
  margin-top: -31px;
  margin-bottom: 10px;
  color: darkred;
}
.alert {
  border: 2px solid transparent;
}
.success {
  color: blue;
  margin-bottom: 10px;
  text-align: center;
}
.error {
  color: red;
  margin-bottom: 10px;
  text-align: center;
}
label  {
  color: white;
}
.info {
  color: white;
}
.form-control--email{
      height: calc(1.2em + 1rem + 20px) ;
}
.error {
  color: orange;
}
</style>
